/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application2.html.erb

// Remove actiontextjs, since we need to use SHRINE FOR UPLOAD IMG
// require("@rails/actiontext");

import jQuery from 'jquery';
import 'bootstrap';

import lazySizes from 'lazysizes';
import lottie from 'lottie-web/build/player/lottie';



window.$ = $;
window.jQuery = jQuery;
window.lazySizes = lazySizes;
window.lottie = lottie;
